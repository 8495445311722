import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../components/Layout'
import SEO from '../components/seo'
import { ContactSection } from '../components/Sections'
import {
  AcademicCapIcon,
  ChatBubbleLeftRightIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline'
import ReactMarkdown from 'react-markdown'

const technologies = [
  {
    id: 1,
    name: 'React',
    website: 'https://reactjs.org/',
    image: (
      <StaticImage
        src="../images/technologies/logo-react.png"
        alt="React logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 2,
    name: 'React Native',
    website: 'https://facebook.github.io/react-native/',
    image: (
      <StaticImage
        src="../images/technologies/logo-react-native.png"
        alt="React Native logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 3,
    name: 'TypeScript',
    website: 'https://www.typescriptlang.org/',
    image: (
      <StaticImage
        src="../images/technologies/logo-typescript.png"
        alt="Typescript logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 4,
    name: 'Gatsby',
    website: 'https://www.gatsbyjs.org',
    image: (
      <StaticImage
        src="../images/technologies/logo-gatsby.png"
        alt="Gatsby logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 5,
    name: 'Node.js',
    website: 'https://nodejs.org/',
    image: (
      <StaticImage
        src="../images/technologies/logo-nodejs.png"
        alt="NodeJS logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 6,
    name: 'Directus',
    website: 'https://directus.io/',
    image: (
      <StaticImage
        src="../images/technologies/logo-directus.png"
        alt="Directus logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
  {
    id: 7,
    name: 'Contentful',
    website: 'https://www.contentful.com/',
    image: (
      <StaticImage
        src="../images/technologies/logo-contentful.png"
        alt="Contentful logo"
        className="max-h-14"
        objectFit="contain"
        placeholder="none"
      />
    ),
  },
]

const IndexPage = ({ data }) => {
  const { allContentfulFirma, allContentfulFreund } = data

  return (
    <Layout>
      <SEO title="Learn something about me and how i work." />

      <div className="sm:px-0 mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-3xl pt-16">
        <div className="mb-20 sm:mb-24">
          <h1 className="font-accent text-3xl sm:text-4xl text-black mb-10">
            About me
          </h1>

          <div className="grid sm:grid-cols-3 gap-8">
            <div className="sm:col-span-1">
              <div className="mb-2">
                <StaticImage
                  src="../images/konstantin.jpg"
                  alt="Konstantin Werner"
                  style={{
                    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
                  }}
                  placeholder="dominantColor"
                  width={75}
                  height={75}
                  className="rounded-full mb-8 sm:mb-0 sm:mr-8 transition duration-200 ease-in-out"
                />
              </div>
              <div className="text-sm">
                <p>
                  <strong>Konstantin Werner</strong>
                  <br />
                  <span className="text-gray-400">Frontend Engineer</span>
                </p>
                <ul className="flex flex-col gap-1 mt-4 text-sm text-gray-500">
                  <li className="flex items-center">
                    <MapPinIcon className="w-4 h-4 mr-1" />
                    Dresden, Germany
                  </li>
                  <li className="flex items-center">
                    <AcademicCapIcon className="w-4 h-4 mr-1" />
                    Diplom-Informatiker (FH)
                  </li>
                  <li className="flex items-center">
                    <ChatBubbleLeftRightIcon className="w-4 h-4 mr-1" />
                    German, English
                  </li>
                </ul>
              </div>
            </div>
            <div className="sm:col-span-2">
              <ReactMarkdown className="formattedText">
                {[
                  'With 9+ years of experience in building frontend applications with React and TypeScript, I enjoy working with innovative tools like Tailwind, Gatsby, Directus and headless backend architectures.',
                  'As a developer I aim for clean, robust and reusable code to ultimately deliver great user experiences – within a team or independently. I believe that open-mindedness, collaboration and true agility are crucial in an everychanging digital industry.',
                ].join('\n\n')}
              </ReactMarkdown>
            </div>
          </div>
        </div>

        <div className="mb-20 sm:mb-24">
          <h2 className="font-accent text-gray-900 text-3xl mb-8">
            Technologies I use
          </h2>

          <ul className="grid gap-5 grid-cols-2 sm:grid-cols-5">
            {technologies.map(({ id, name, website, image }) => (
              <li
                key={id}
                className="flex justify-center items-center transition filter grayscale hover:grayscale-0 hover:scale-105"
              >
                <a
                  href={website}
                  title={`Go to ${name} website`}
                  className="no-underline"
                >
                  {image}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-20 sm:mb-24" id="companies">
          <h2 className="font-accent text-gray-900 text-3xl mb-8">
            Companies & partners
          </h2>

          <ul className="grid gap-5 grid-cols-2 sm:grid-cols-5">
            {allContentfulFirma.edges.map(({ node }) => {
              const { id, website, name, logo } = node

              return (
                <li
                  key={id}
                  className="flex justify-center items-center transition filter grayscale hover:grayscale-0 hover:scale-105"
                >
                  <a
                    href={website}
                    title={`Go to ${name} website`}
                    className="no-underline"
                  >
                    <GatsbyImage
                      image={getImage(logo.gatsbyImageData)}
                      alt={`${name} Logo`}
                    />
                  </a>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="mb-20 sm:mb-24" id="friends">
          <h2 className="font-accent text-gray-900 text-3xl mb-8">
            Colleagues & friends
          </h2>

          <div className="max-w-md">
            <p>
              Team work is key. I think that best results can be achieved by
              exchanging knowledge and ideas. I am happy to be part of a strong
              network of independent designers, developers and consultants.
            </p>
          </div>

          <ul className="grid grid-cols-1 my-10 gap-5 sm:grid-cols-2">
            {allContentfulFreund.edges.map(({ node }) => {
              const { name, profession, website, id, image } = node

              return (
                <div
                  key={id}
                  className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 transition duration-200 hover:shadow-lg hover:scale-105 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand"
                >
                  {image && (
                    <div className="flex-shrink-0">
                      <GatsbyImage
                        image={getImage(image.gatsbyImageData)}
                        className="rounded-full"
                        alt={name}
                      />
                      {/* <img
                            className="h-10 w-10 rounded-full"
                            src={image?.fixed?.src}
                            alt=""
                          /> */}
                    </div>
                  )}
                  <div className="flex-1 min-w-0">
                    <a href={website} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900 mb-0">
                        {name}
                      </p>
                      {profession && (
                        <p className="text-sm text-gray-500 sm:truncate mb-0">
                          {profession}
                        </p>
                      )}
                    </a>
                  </div>
                </div>
              )
            })}
          </ul>
        </div>

        <div style={{ margin: '5rem 0 0 ' }}>
          <ContactSection />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulFirma(
      filter: { showAsPartner: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          name
          website
          showAsPartner
          logo {
            id
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, height: 70)
          }
        }
      }
    }
    allContentfulFreund(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          name
          profession
          website
          image {
            id
            gatsbyImageData(
              layout: FIXED
              placeholder: DOMINANT_COLOR
              width: 45
              height: 45
            )
          }
        }
      }
    }
  }
`

export default IndexPage
